import React, { useContext, useEffect } from 'react';
import { Script } from 'gatsby';
import { Intercom } from 'react-live-chat-loader';
import { library } from '@fortawesome/fontawesome-svg-core';

//duotone
import { faShoppingCart } from '@fortawesome/pro-duotone-svg-icons/faShoppingCart';
import { faPhoneAlt } from '@fortawesome/pro-duotone-svg-icons/faPhoneAlt';
import { faPhoneOffice } from '@fortawesome/pro-duotone-svg-icons/faPhoneOffice';
import { faUserTie } from '@fortawesome/pro-duotone-svg-icons/faUserTie';
import { faUserHeadset } from '@fortawesome/pro-duotone-svg-icons/faUserHeadset';
import { faCannabis } from '@fortawesome/pro-duotone-svg-icons/faCannabis';
import { faTruckCouch } from '@fortawesome/pro-duotone-svg-icons/faTruckCouch';
import { faStar } from '@fortawesome/pro-duotone-svg-icons/faStar';
import { faSprinkler } from '@fortawesome/pro-duotone-svg-icons/faSprinkler';
import { faVacuum } from '@fortawesome/pro-duotone-svg-icons/faVacuum';
import { faQuoteRight } from '@fortawesome/pro-duotone-svg-icons/faQuoteRight';
import { faPlug } from '@fortawesome/pro-duotone-svg-icons/faPlug';
import { faSortCircleUp } from '@fortawesome/pro-duotone-svg-icons/faSortCircleUp';
import { faSwimmingPool } from '@fortawesome/pro-duotone-svg-icons/faSwimmingPool';
import { faAirConditioner } from '@fortawesome/pro-duotone-svg-icons/faAirConditioner';
import { faFish } from '@fortawesome/pro-duotone-svg-icons/faFish';
import { faAppleCrate } from '@fortawesome/pro-duotone-svg-icons/faAppleCrate';
import { faBriefcase } from '@fortawesome/pro-duotone-svg-icons/faBriefcase';
import { faBlog } from '@fortawesome/pro-duotone-svg-icons/faBlog';
import { faCalendarCheck } from '@fortawesome/pro-duotone-svg-icons/faCalendarCheck';
import { faUsers } from '@fortawesome/pro-duotone-svg-icons/faUsers';
import { faNewspaper } from '@fortawesome/pro-duotone-svg-icons/faNewspaper';
import { faEnvelopeOpenText } from '@fortawesome/pro-duotone-svg-icons/faEnvelopeOpenText';
import { faListAlt } from '@fortawesome/pro-duotone-svg-icons/faListAlt';
import { faIndustry } from '@fortawesome/pro-duotone-svg-icons/faIndustry';
import { faCubes } from '@fortawesome/pro-duotone-svg-icons/faCubes';
import { faBuilding } from '@fortawesome/pro-duotone-svg-icons/faBuilding';
import { faHeadset } from '@fortawesome/pro-duotone-svg-icons/faHeadset';
import { faHandshake } from '@fortawesome/pro-duotone-svg-icons/faHandshake';
import { faRoute } from '@fortawesome/pro-duotone-svg-icons/faRoute';
import { faClipboardListCheck } from '@fortawesome/pro-duotone-svg-icons/faClipboardListCheck';
import { faMobileAlt } from '@fortawesome/pro-duotone-svg-icons/faMobileAlt';
import { faFileInvoiceDollar } from '@fortawesome/pro-duotone-svg-icons/faFileInvoiceDollar';
import { faFishFins } from '@fortawesome/pro-duotone-svg-icons/faFishFins';
import { faMobileButton } from '@fortawesome/pro-duotone-svg-icons/faMobileButton';
import { faListCheck } from '@fortawesome/pro-duotone-svg-icons/faListCheck';
import { faWineBottle } from '@fortawesome/pro-duotone-svg-icons/faWineBottle';
import { faWashingMachine } from '@fortawesome/pro-duotone-svg-icons/faWashingMachine';
import { faRug } from '@fortawesome/pro-duotone-svg-icons/faRug';
import { faPlateUtensils } from '@fortawesome/pro-duotone-svg-icons/faPlateUtensils';
import { faChimney } from '@fortawesome/pro-duotone-svg-icons/faChimney';
import { faUserHelmetSafety } from '@fortawesome/pro-duotone-svg-icons/faUserHelmetSafety';
import { faTruckFast } from '@fortawesome/pro-duotone-svg-icons/faTruckFast';
import { faShredder } from '@fortawesome/pro-duotone-svg-icons/faShredder';
import { faPlugCircleBolt } from '@fortawesome/pro-duotone-svg-icons/faPlugCircleBolt';
import { faFireExtinguisher } from '@fortawesome/pro-duotone-svg-icons/faFireExtinguisher';
import { faGasPump } from '@fortawesome/pro-duotone-svg-icons/faGasPump';
import { faGarage } from '@fortawesome/pro-duotone-svg-icons/faGarage';
import { faToolbox } from '@fortawesome/pro-duotone-svg-icons/faToolbox';
import { faHouseTree } from '@fortawesome/pro-duotone-svg-icons/faHouseTree';
import { faLockKeyhole } from '@fortawesome/pro-duotone-svg-icons/faLockKeyhole';
import { faCartFlatbedBoxes } from '@fortawesome/pro-duotone-svg-icons/faCartFlatbedBoxes';
import { faBroom } from '@fortawesome/pro-duotone-svg-icons/faBroom';
import { faBiohazard } from '@fortawesome/pro-duotone-svg-icons/faBiohazard';
import { faScrewdriverWrench } from '@fortawesome/pro-duotone-svg-icons/faScrewdriverWrench';
import { faBug } from '@fortawesome/pro-duotone-svg-icons/faBug';
import { faPaw } from '@fortawesome/pro-duotone-svg-icons/faPaw';
import { faPipeValve } from '@fortawesome/pro-duotone-svg-icons/faPipeValve';
import { faPoop } from '@fortawesome/pro-duotone-svg-icons/faPoop';
import { faHose } from '@fortawesome/pro-duotone-svg-icons/faHose';
import { faHouse } from '@fortawesome/pro-duotone-svg-icons/faHouse';
import { faTruckPlow } from '@fortawesome/pro-duotone-svg-icons/faTruckPlow';
import { faTreeLarge } from '@fortawesome/pro-duotone-svg-icons/faTreeLarge';
import { faWindowFrame } from '@fortawesome/pro-duotone-svg-icons/faWindowFrame';
import { faCameraCctv } from '@fortawesome/pro-duotone-svg-icons/faCameraCctv';
import { faCalendarClock } from '@fortawesome/pro-duotone-svg-icons/faCalendarClock';
import { faCommentSms } from '@fortawesome/pro-duotone-svg-icons/faCommentSms';
import { faMapLocationDot } from '@fortawesome/pro-duotone-svg-icons/faMapLocationDot';
import { faSeedling } from '@fortawesome/pro-duotone-svg-icons/faSeedling';
import { faUser } from '@fortawesome/pro-duotone-svg-icons/faUser';
import { faCirclePhone } from '@fortawesome/pro-duotone-svg-icons/faCirclePhone';
import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons/faEnvelope';
import { faShieldKeyhole } from '@fortawesome/pro-duotone-svg-icons/faShieldKeyhole';
import { faBellOn } from '@fortawesome/pro-duotone-svg-icons/faBellOn';
import { faMagnifyingGlass } from '@fortawesome/pro-duotone-svg-icons/faMagnifyingGlass';
import { faArrowsRotate } from '@fortawesome/pro-duotone-svg-icons/faArrowsRotate';
import { faCloudCheck } from '@fortawesome/pro-duotone-svg-icons/faCloudCheck';
import { faGlobe } from '@fortawesome/pro-duotone-svg-icons/faGlobe';
import { faCircleExclamation } from '@fortawesome/pro-duotone-svg-icons/faCircleExclamation';
import { faUserGroup } from '@fortawesome/pro-duotone-svg-icons/faUserGroup';
import { faBuildingShield } from '@fortawesome/pro-duotone-svg-icons/faBuildingShield';
import { faTimer } from '@fortawesome/pro-duotone-svg-icons/faTimer';
import { faVault } from '@fortawesome/pro-duotone-svg-icons/faVault';
import { faCode } from '@fortawesome/pro-duotone-svg-icons/faCode';
import { faShieldCheck } from '@fortawesome/pro-duotone-svg-icons/faShieldCheck';
import { faUserShield } from '@fortawesome/pro-duotone-svg-icons/faUserShield';
import { faUserLock } from '@fortawesome/pro-duotone-svg-icons/faUserLock';
import { faShieldHalved } from '@fortawesome/pro-duotone-svg-icons/faShieldHalved';
import { faMedal } from '@fortawesome/pro-duotone-svg-icons/faMedal';
import { faSearch } from '@fortawesome/pro-duotone-svg-icons/faSearch';
import { faHandsHoldingDollar } from '@fortawesome/pro-duotone-svg-icons/faHandsHoldingDollar';
import { faCircleNodes } from '@fortawesome/pro-duotone-svg-icons/faCircleNodes';
import { faCalendarDays } from '@fortawesome/pro-duotone-svg-icons/faCalendarDays';
import { faCircleDollar } from '@fortawesome/pro-duotone-svg-icons/faCircleDollar';
import { faCircleDollarToSlot } from '@fortawesome/pro-duotone-svg-icons/faCircleDollarToSlot';
import { faBuildingFlag } from '@fortawesome/pro-duotone-svg-icons/faBuildingFlag';
import { faFileImage } from '@fortawesome/pro-duotone-svg-icons/faFileImage';
import { faHandHoldingDollar } from '@fortawesome/pro-duotone-svg-icons/faHandHoldingDollar';
import { faHandHoldingCircleDollar } from '@fortawesome/pro-duotone-svg-icons/faHandHoldingCircleDollar';
import { faCreditCard } from '@fortawesome/pro-duotone-svg-icons/faCreditCard';
import { faEnvelopesBulk } from '@fortawesome/pro-duotone-svg-icons/faEnvelopesBulk';
import { faMagnifyingGlassChart } from '@fortawesome/pro-duotone-svg-icons/faMagnifyingGlassChart';
import { faThumbsUp } from '@fortawesome/pro-duotone-svg-icons/faThumbsUp';
import { faAward } from '@fortawesome/pro-duotone-svg-icons/faAward';
import { faLightbulbOn } from '@fortawesome/pro-duotone-svg-icons/faLightbulbOn';
import { faCircleStar } from '@fortawesome/pro-duotone-svg-icons/faCircleStar';
import { faHandSparkles } from '@fortawesome/pro-duotone-svg-icons/faHandSparkles';
import { faGear } from '@fortawesome/pro-duotone-svg-icons/faGear';
import { faBoxTaped } from '@fortawesome/pro-duotone-svg-icons/faBoxTaped';
import { faTruckMedical } from '@fortawesome/pro-duotone-svg-icons/faTruckMedical';
import { faShareFromSquare } from '@fortawesome/pro-duotone-svg-icons/faShareFromSquare';
import { faCirclePlus } from '@fortawesome/pro-duotone-svg-icons/faCirclePlus';
import { faSliders } from '@fortawesome/pro-duotone-svg-icons/faSliders';
import { faChartMixedUpCircleDollar } from '@fortawesome/pro-duotone-svg-icons/faChartMixedUpCircleDollar';
import { faGears } from '@fortawesome/pro-duotone-svg-icons/faGears';
import { faServer } from '@fortawesome/pro-duotone-svg-icons/faServer';
import { faKey } from '@fortawesome/pro-duotone-svg-icons/faKey';
import { faCircleCheck as faDuotoneCircleCheck } from '@fortawesome/pro-duotone-svg-icons/faCircleCheck';

//brand
import { faSquareFacebook } from '@fortawesome/free-brands-svg-icons/faSquareFacebook';
import { faSquareXTwitter } from '@fortawesome/free-brands-svg-icons/faSquareXTwitter';
import { faSquareInstagram } from '@fortawesome/free-brands-svg-icons/faSquareInstagram';
import { faSquareYoutube } from '@fortawesome/free-brands-svg-icons/faSquareYoutube';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin';
import { faSquareWhatsapp } from '@fortawesome/free-brands-svg-icons/faSquareWhatsapp';

//solid
import { faEnvelope as farEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons/faCircleCheck';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faMinus } from '@fortawesome/free-solid-svg-icons/faMinus';
import { faLightbulbGear as faCompanyLightbulbGear } from '@fortawesome/pro-solid-svg-icons/faLightbulbGear';
import { faHandshakeSimple as faCompanyHandshakeSimple } from '@fortawesome/pro-solid-svg-icons/faHandshakeSimple';
import { faChartLineUp as faCompanyChartLineUp } from '@fortawesome/pro-solid-svg-icons/faChartLineUp';

import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import theme from '../../theme';

import { Nav } from './Nav';
import { Footer } from './Footer';
import FormContext, { FormProvider } from '../../context/FormContext';

import 'typeface-poppins';
import 'typeface-open-sans';
import 'typeface-roboto';

library.add(
  faPhoneOffice,
  // faFacebook,
  //faFacebookSquare,
  faSquareFacebook,
  // faTwitter,
  //faTwitterSquare,
  faSquareXTwitter,
  //faInstagramSquare,
  faSquareInstagram,
  //faYoutubeSquare,
  faSquareYoutube,
  faLinkedin,
  //faWhatsappSquare,
  faSquareWhatsapp,
  faPhoneAlt,
  faArrowLeft,
  faArrowRight,
  faShoppingCart,
  faQuoteRight,
  // faHandHoldingSeedling,
  // faConciergeBell,
  // faEnvelopeOpenDollar,
  faStar,
  faUserTie,
  faUserHeadset,
  // faHandHoldingBox,
  // faTruckContainer,
  // faBoxAlt,
  // faBurgerSoda,
  faCannabis,
  faTruckCouch,
  // faAmbulance,
  // faDollyFlatbed,
  // faDebug,
  faSprinkler,
  // faTrees,
  faVacuum,
  // faToilet,
  faPlug,
  // faVacuumRobot,
  faSortCircleUp,
  faSwimmingPool,
  // faOven,
  faAirConditioner,
  faFish,
  // faTruckLoading,
  // faShippingTimed,
  faAppleCrate,
  faBriefcase,
  faBlog,
  // faCogs,
  faCalendarCheck,
  faUsers,
  faNewspaper,
  // faRss,
  faEnvelopeOpenText,
  faListAlt,
  faIndustry,
  faCubes,
  faBuilding,
  faHeadset,
  faHandshake,
  faRoute,
  faClipboardListCheck,
  faMobileAlt,
  faFileInvoiceDollar,
  faFishFins,
  faMobileButton,
  faListCheck,
  faWineBottle,
  faWashingMachine,
  faRug,
  faPlateUtensils,
  faChimney,
  faUserHelmetSafety,
  faTruckFast,
  faShredder,
  faPlugCircleBolt,
  faFireExtinguisher,
  faGasPump,
  faGarage,
  faToolbox,
  faHouseTree,
  faLockKeyhole,
  faCartFlatbedBoxes,
  faBroom,
  faBiohazard,
  faScrewdriverWrench,
  faBug,
  faPaw,
  faPipeValve,
  faPoop,
  faHose,
  faHouse,
  faTruckPlow,
  faTreeLarge,
  faWindowFrame,
  faCameraCctv,
  faCalendarClock,
  faCommentSms,
  faMapLocationDot,
  faSeedling,
  faUser,
  faCirclePhone,
  faEnvelope,
  faShieldKeyhole,
  faBellOn,
  faMagnifyingGlass,
  faArrowsRotate,
  faCloudCheck,
  faGlobe,
  faCircleExclamation,
  faUserGroup,
  faBuildingShield,
  faTimer,
  faVault,
  faCode,
  faShieldCheck,
  faUserShield,
  faUserLock,
  faShieldHalved,
  // faLinkedinIn,
  farEnvelope,
  faXmark,
  faMedal,
  faCheck,
  faSearch,
  faCircleCheck,
  faHandsHoldingDollar,
  faCircleNodes,
  faCalendarDays,
  faCircleDollar,
  faCircleDollarToSlot,
  // faChartMixed,
  faBuildingFlag,
  faFileImage,
  faHandHoldingDollar,
  faHandHoldingCircleDollar,
  faCreditCard,
  faEnvelopesBulk,
  faMagnifyingGlassChart,
  faThumbsUp,
  faAward,
  faLightbulbOn,
  faCircleStar,
  faHandSparkles,
  faGear,
  faCirclePlus,
  faPlay,
  faBoxTaped,
  faTruckMedical,
  faPlus,
  faMinus,
  faShareFromSquare,
  faSliders,
  faChartMixedUpCircleDollar,
  faCompanyLightbulbGear,
  faCompanyHandshakeSimple,
  faCompanyChartLineUp,
  faGears,
  faServer,
  faKey,
  faDuotoneCircleCheck
);

export const Layout = ({ children }) => {
  const { setIsLoaded } = useContext(FormContext);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Nav />
      {/* <Script
				src='https://app-sj02.marketo.com/js/forms2/js/forms2.min.js'
				strategy='idle'
				onLoad={() => setIsLoaded(true)}
			/> */}
      {children}
      <Footer />
      {/* <Intercom color='#1689CA' /> */}
    </MuiThemeProvider>
  );
};
